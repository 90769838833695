import React from "react"
import './style.scss'

const StaticPageHeader = ({title, subheader}) => {

  return (
    <div className="static-page-header">
      <div className="grid-section">
        <h1 className="gradient-title">{title}</h1>
        <p className="subheader">{subheader}</p>
      </div>

    </div>
  )
}

export default StaticPageHeader
