import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from '../components/Terms';
import StaticPageHeader from "../components/StaticPageHeader"

const TermsOfService = () => {
  const [firstRender, setFirstRender] = useState(null)
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
    setFirstRender(true)
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/terms-of-service",
      "@type": "webpage",
      "name": "Hubble Contacts Terms of Service | Hubble",
      "description": "Access the Terms of Service policy that contains important information you should have when visiting hubblecontacts.com. Understand the rules and guidelines required by visitors to our site.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]


  return (
    <Layout>
      <SEO title="Hubble Contacts Terms of Service"
        description="Access the Terms of Service policy that contains important information you should have when visiting hubblecontacts.com. Understand the rules and guidelines required by visitors to our site."
        schemaMarkup={schemaMarkup} />
      <StaticPageHeader title="Terms of Service" subheader="Vision Path, Inc. | Hubble Contacts"/>
        {firstRender && <Terms />}
    </Layout>
  )
}

export default TermsOfService
